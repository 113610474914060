import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  justify-content: space-between;
`

const TimerUnit = styled.div<{ position: 'left' | 'middle' | 'right' }>`
  width: 102px;
  height: 83px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  padding: 8px 16px;
  border-radius: ${(props) =>
    props.position === 'left' ? '20px 0 0 20px' : props.position === 'right' ? '0 20px 20px 0' : '0'};
`

const TimerValue = styled.span`
  font-size: 30px;
  line-height: 120%;
  font-weight: bold;
  color: rgba(67, 70, 71, 0.9);
`

const TimerLabel = styled.span`
  font-size: 10px;
  line-height: 120%;
  color: #6c757d;
  text-transform: lowercase;
`

interface CountdownTimerProps {
  endDate: number
}

const CountdownTimer: React.FC<CountdownTimerProps> = ({ endDate }) => {
  const calculateTimeLeft = useCallback(() => {
    const difference = +new Date(endDate) - +new Date()
    let timeLeft = {
      days: 0,
      hours: 0,
      minutes: 0,
    }

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
      }
    }

    return timeLeft
  }, [endDate])

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft())

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft())
    }, 1000)

    return () => clearInterval(timer)
  }, [calculateTimeLeft])

  const { t } = useTranslation()
  return (
    <Container>
      <TimerUnit position="left">
        <TimerValue>{timeLeft.days.toString().padStart(2, '0')}</TimerValue>
        <TimerLabel>{t('tokenSaleInfo.days')}</TimerLabel>
      </TimerUnit>
      <TimerUnit position="middle">
        <TimerValue>{timeLeft.hours.toString().padStart(2, '0')}</TimerValue>
        <TimerLabel>{t('tokenSaleInfo.hours')}</TimerLabel>
      </TimerUnit>
      <TimerUnit position="right">
        <TimerValue>{timeLeft.minutes.toString().padStart(2, '0')}</TimerValue>
        <TimerLabel>{t('tokenSaleInfo.minutes')}</TimerLabel>
      </TimerUnit>
    </Container>
  )
}

export default CountdownTimer

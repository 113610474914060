export enum TokenSymbol {
  lpXFI = 'lpXFI',
  esXFI = 'esXFI',
  xfi = 'xfi',
  eth = 'eth',
  weth = 'weth',
  usdt = 'usdt',
  xUsd = 'xusd',
  wxfi = 'wxfi',
  usdc = 'usdc',
  wbtc = 'wbtc',
}

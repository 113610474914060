import React from 'react'
import styled from 'styled-components'

import SWA from '../../../assets/svg/swa-token.svg'

const Container = styled.div<{ disabled: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 24px;
  border-radius: 15px;
  background-color: white;
  gap: 12px;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`

const Label = styled.h3`
  font-size: 14px;
  color: #00a3ff;
  font-weight: 500;
  margin: 0;
`

const PriceContainer = styled.div`
  display: flex;
  align-items: center;
`

const SWAIcon = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 6px;
`

const Price = styled.p`
  font-size: 18px;
  font-weight: 400;
  margin: 0;
  line-height: 120%;
  color: black;
`

export interface PriceDisplayProps {
  label: string
  price: number
}

const PriceDisplay: React.FC<PriceDisplayProps> = ({ label, price }) => (
  <Container disabled={!price}>
    {price ? (
      <>
        <Label>{label}</Label>

        <PriceContainer>
          <SWAIcon src={SWA} />
          <Price>1 SWA = {price.toFixed(2)} USDT</Price>
        </PriceContainer>
      </>
    ) : (
      ''
    )}
  </Container>
)

export default PriceDisplay

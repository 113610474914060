import { SupportedChainId } from 'constants/chainsinfo'
import { BigNumber } from 'ethers'
import { useActiveWeb3React } from 'hooks/web3'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { calculateGasMargin } from 'utils/calculateGasMargin'
import { ZERO } from 'utils/isZero'

export interface ITxTemplateInfo {
  estimatedGasLimitFunc: (showError?: boolean | undefined) => Promise<BigNumber>
  usedGasLimit?: BigNumber
}

export const useEstimatesGasValue = (info?: ITxTemplateInfo) => {
  const [estimatedGas, setEstimatedGas] = useState<BigNumber>(ZERO)
  const [estimatedGasPrice, setEstimatedGasPrice] = useState<BigNumber>(ZERO)
  const { library } = useActiveWeb3React()

  const { chainId = SupportedChainId.XFI_TESTNET } = useActiveWeb3React()

  useEffect(() => {
    if (!info) {
      return
    }
    const { estimatedGasLimitFunc } = info
    const getEstimatedGas = async () => {
      if (!library) {
        return
      }
      const gas = await estimatedGasLimitFunc()

      const gasPrice = await library.getSigner().getGasPrice()

      setEstimatedGas(calculateGasMargin(chainId, gas.mul(gasPrice)))
      setEstimatedGasPrice(gasPrice)
    }

    getEstimatedGas()
  }, [info, chainId, library])

  return { estimatedGas, estimatedGasPrice }
}

const IconStyled = styled.img`
  width: 16px;
  height: 16px;
`

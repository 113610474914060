import { useTronSaleAddress } from 'constants/app-contracts'
import { BigNumber } from 'ethers'
import { useTronWebContract } from 'hooks/tronweb'
import { useIntervalEffect } from 'hooks/useIntervalEffect'
import { useCallback, useState } from 'react'
import { ZERO } from 'utils/isZero'

const TIMEOUT = 3000

export interface IStage {
  conversionRate: number
  deadline: number
}

const makeNumberFromBn = (bn: BigNumber) => {
  return bn.toNumber()
}

export const getStagePrice = (stage: IStage) => {
  return 100 / stage.conversionRate
}

export const getEstimatedProfit = (amount: BigNumber, price: number) => {
  if (!price) {
    return ZERO
  }

  return amount
    .mul(48)
    .div(100)
    .div(Math.floor(price * 100))
    .mul(100)
}

export interface ITokenSaleData {
  currentStage?: number
  stagesInfo?: IStage[]
  isLoading: boolean
}

export const useFetchTokenSaleData = (): ITokenSaleData => {
  const saleAddress = useTronSaleAddress()
  const contract = useTronWebContract(saleAddress)

  const [currentStage, setCurrentStage] = useState<number | undefined>()
  const [stagesInfo, setStagesInfo] = useState<IStage[] | undefined>()

  const fetchCurrentStage = useCallback(async () => {
    if (!saleAddress || !contract) return

    const data = await contract.getCurrentStage().call()

    setCurrentStage(makeNumberFromBn(data))
  }, [saleAddress, contract])

  useIntervalEffect(fetchCurrentStage, TIMEOUT)

  const fetchStagesInfo = useCallback(async () => {
    if (!saleAddress || !contract) return

    const data1 = await contract.stages(0).call()
    const data2 = await contract.stages(1).call()
    const data3 = await contract.stages(2).call()

    setStagesInfo([
      {
        conversionRate: makeNumberFromBn(data1.conversionRate),
        deadline: makeNumberFromBn(data1.deadline),
      },
      {
        conversionRate: makeNumberFromBn(data2.conversionRate),
        deadline: makeNumberFromBn(data2.deadline),
      },
      {
        conversionRate: makeNumberFromBn(data3.conversionRate),
        deadline: makeNumberFromBn(data3.deadline),
      },
    ])
  }, [saleAddress, contract])

  useIntervalEffect(fetchStagesInfo, TIMEOUT)

  return { currentStage, stagesInfo, isLoading: !stagesInfo || stagesInfo.length === 0 }
}

export const useSaleDatesTrc = () => {
  const [startTime, setStartTime] = useState<Date | undefined>(new Date())

  const saleAddress = useTronSaleAddress()
  const contract = useTronWebContract(saleAddress)

  const fetchSaleStartTime = useCallback(async () => {
    if (!saleAddress || !contract) return

    const data = await contract.saleStartTime().call()

    setStartTime(new Date(data * 1000))
  }, [saleAddress, contract])

  useIntervalEffect(fetchSaleStartTime, TIMEOUT)

  return { startTime }
}

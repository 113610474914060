/* eslint-disable import/no-unused-modules */
import { TokenSymbol } from 'components/blocks/AmountInput/TokenSymbol'
import {
  ARBITRUM_USDT as ArbitrumUsdtAddress,
  BASE_USDT as BaseUsdtAddress,
  BSC_USDT as BscUsdtAddress,
  ETH_USDT as EthUsdtAddress,
  LP_ADDRESS,
  POLYGON_USDT as PolygonUsdtAddress,
  TRON_USDT as TronUsdtAddress,
  USDT_TESTNET,
  useTronUSDTAddress,
  WETH_XFI,
  XUSD_TESTNET,
} from 'constants/app-contracts'
import { SupportedChainId } from 'constants/chainsinfo'
import { useMemo } from 'react'
import { isAddress } from 'utils'

import esXFI from '../assets/icons/tokens/esXfi.svg'
import ETHIcon from '../assets/icons/tokens/eth.svg'
import lpXFI from '../assets/icons/tokens/lp.svg'
import usdcIcon from '../assets/icons/tokens/usdc.svg'
import usdtIcon from '../assets/icons/tokens/usdt.svg'
import WBTCIcon from '../assets/icons/tokens/wbtc.png'
import WETHIcon from '../assets/icons/tokens/weth.png'
import xfiIcon from '../assets/icons/tokens/xfi.svg'
import xUsdIcon from '../assets/icons/tokens/xUsd.svg'
import arbitrumSvg from '../assets/svg/small/arbitrum.svg'
import baseSvg from '../assets/svg/small/base.svg'
import bscSvg from '../assets/svg/small/bsc.svg'
import ethereumIcon from '../assets/svg/small/ethereum.svg'
import polygonSvg from '../assets/svg/small/polygon.svg'
import tronSvg from '../assets/svg/small/tron.svg'
import { useActiveWeb3React } from './web3'

export interface ITokenAsset {
  icon?: string
  symbol: TokenSymbol
  name?: string
  address?: string
  chainIcon?: string
}

const XFI_USDT: ITokenAsset = {
  icon: usdtIcon,
  symbol: TokenSymbol.usdt,
  name: 'Tether USD (XFI)',
  address: USDT_TESTNET,
  chainIcon: xfiIcon,
}

const TRON_USDT: ITokenAsset = {
  icon: usdtIcon,
  symbol: TokenSymbol.usdt,
  name: 'TRON',
  address: TronUsdtAddress,
  chainIcon: tronSvg,
}

const BASE_USDT: ITokenAsset = {
  icon: usdtIcon,
  symbol: TokenSymbol.usdt,
  name: 'BASE',
  address: BaseUsdtAddress,
  chainIcon: baseSvg,
}

const POLYGON_USDT: ITokenAsset = {
  icon: usdtIcon,
  symbol: TokenSymbol.usdt,
  name: 'POLYGON',
  address: PolygonUsdtAddress,
  chainIcon: polygonSvg,
}

const ARBITRUM_USDT: ITokenAsset = {
  icon: usdtIcon,
  symbol: TokenSymbol.usdt,
  name: 'ARBITRUM',
  address: ArbitrumUsdtAddress,
  chainIcon: arbitrumSvg,
}

const ETH_USDT: ITokenAsset = {
  icon: usdtIcon,
  symbol: TokenSymbol.usdt,
  name: 'ETH',
  address: EthUsdtAddress,
  chainIcon: ethereumIcon,
}

const BSC_USDT: ITokenAsset = {
  icon: usdtIcon,
  symbol: TokenSymbol.usdt,
  name: 'BSC',
  address: BscUsdtAddress,
  chainIcon: bscSvg,
}

export const TOKENS: ITokenAsset[] = [
  {
    icon: lpXFI,
    symbol: TokenSymbol.lpXFI,
    name: 'XFI LP',
    address: LP_ADDRESS,
    chainIcon: xfiIcon,
  },
  {
    icon: esXFI,
    symbol: TokenSymbol.esXFI,
    name: 'esXFI',
    address: '',
    chainIcon: xfiIcon,
  },
  {
    icon: xfiIcon,
    symbol: TokenSymbol.xfi,
    name: 'CrossFi Token',
    address: '',
    chainIcon: xfiIcon,
  },
  {
    icon: ETHIcon,
    symbol: TokenSymbol.eth,
    name: 'Ethereum',
    address: '',
    chainIcon: xfiIcon,
  },
  {
    icon: xfiIcon,
    symbol: TokenSymbol.wxfi,
    name: 'Wrapped XFI',
    address: '0x28cC5eDd54B1E4565317C3e0Cfab551926A4CD2a',
    chainIcon: xfiIcon,
  },
  {
    icon: WETHIcon,
    symbol: TokenSymbol.weth,
    name: 'Wrapped Ethereum',
    address: WETH_XFI,
    chainIcon: xfiIcon,
  },
  {
    icon: WBTCIcon,
    symbol: TokenSymbol.wbtc,
    name: 'Wrapped Bitcoin',
    address: '0x914938e817bd0b5f7984007dA6D27ca2EfB9f8f4',
    chainIcon: xfiIcon,
  },
  XFI_USDT,
  TRON_USDT,
  BASE_USDT,
  BSC_USDT,
  POLYGON_USDT,
  ARBITRUM_USDT,
  ETH_USDT,
  {
    icon: usdcIcon,
    symbol: TokenSymbol.usdc,
    name: 'USD Coin',
    address: '0x562CA64F6Cf6122CA655A6ca09A7d19495f23089',
    chainIcon: xfiIcon,
  },
  {
    icon: xUsdIcon,
    symbol: TokenSymbol.xUsd,
    name: 'XUSD',
    address: XUSD_TESTNET,
    chainIcon: xfiIcon,
  },
]

// by symbol
// by token address
export const useTokenAsset = (query?: string) => {
  const { chainId = SupportedChainId.XFI_TESTNET } = useActiveWeb3React()

  const tronUsdtAddress = useTronUSDTAddress()

  const queryLower = query?.toLowerCase()

  const foundToken = useMemo(() => {
    if (!queryLower) return undefined

    if (queryLower === tronUsdtAddress?.toLowerCase()) {
      return TRON_USDT
    }

    let token
    if (isAddress(queryLower) && chainId) {
      token = TOKENS.find((token) => {
        return token.address?.toLowerCase() === queryLower
      })
    } else {
      token = TOKENS.find((token) => token.symbol?.toLowerCase() === queryLower)
    }

    return token
  }, [chainId, queryLower, tronUsdtAddress])

  return foundToken
}

import Loading from 'components/Loading'
import { RowGapped } from 'components/Row'
import { ITxTemplateInfo, useEstimatesGasValue } from 'components/TransactionInfo/TransactionInfo'
import { useMemo } from 'react'
import { BN_1E18, ZERO } from 'utils/isZero'
import { formatDecimal } from 'utils/numberWithCommas'

const GAS_WEI_DECIMALS = 10

export const useTxEstimatedGasValue = (txInfo?: ITxTemplateInfo) => {
  const { estimatedGas, estimatedGasPrice } = useEstimatesGasValue(txInfo)

  const [integer, decimals] = useMemo(() => {
    if (!estimatedGas || !estimatedGasPrice) return ['0', '00']
    const gasInWei = estimatedGas.mul(estimatedGasPrice).div(BN_1E18)

    return formatDecimal(gasInWei, 12, GAS_WEI_DECIMALS).split('.')
  }, [estimatedGas, estimatedGasPrice])

  const gasValue = useMemo(() => {
    if (txInfo?.usedGasLimit && txInfo.usedGasLimit.gt(ZERO) && estimatedGasPrice) {
      const actualGasInWei = txInfo.usedGasLimit.mul(estimatedGasPrice).div(BN_1E18)

      return formatDecimal(actualGasInWei, 12, GAS_WEI_DECIMALS)
    }

    if (!+integer && !+decimals) {
      return ''
    }

    return `${integer}.${decimals}`
  }, [txInfo, integer, decimals, estimatedGasPrice])

  return { gasValue }
}

export const FormActionBtn = ({
  pending,
  labelInProgress,
  labelActive,
}: {
  pending: boolean
  txInfo?: ITxTemplateInfo
  labelActive: string
  labelInProgress: string
}) => {
  return (
    <Loading loading={pending} loadingLabel={labelInProgress}>
      <RowGapped justify="center" gap="10px">
        {labelActive}
      </RowGapped>
    </Loading>
  )
}

import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { IStage } from './TimeLeftChecker'

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
`

const StageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 12px;
`

const StageBar = styled.div`
  width: 100%;
  height: 16px;
  flex-shrink: 0;
  background-color: white;
  border-radius: 38px;
  overflow: hidden;
`

const StageFill = styled.div<{ fillPercentage: number; isActive: boolean }>`
  width: ${(props) => `${props.fillPercentage}%`};
  height: 100%;
  background-color: ${(props) => (props.isActive ? '#D6EAFF' : 'white')};
  border-radius: 38px;
  position: relative;
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0.1) 25%,
      rgba(255, 255, 255, 0.2) 50%,
      rgba(255, 255, 255, 0.1) 75%
    );
    background-size: 200% 100%;
    animation: shine 1.5s infinite linear;
  }

  @keyframes shine {
    0% {
      background-position: 100% 0;
    }
    100% {
      background-position: -100% 0;
    }
  }
`

const StageLabel = styled.div<{ isActive: boolean }>`
  font-size: 15px;
  line-height: 120%;
  color: ${({ isActive }) => (isActive ? 'rgba(0, 133, 255, 0.8)' : 'rgba(67, 70, 71, 0.30)')};
  font-weight: 500;
  text-transform: uppercase;
`

export interface IStageWithPercentage extends IStage {
  percentage: number
}

export interface StageIndicatorProps {
  stagesInfo: IStageWithPercentage[]
  currentStage: number
}

const StageIndicator: React.FC<StageIndicatorProps> = ({ stagesInfo, currentStage }) => {
  const { t } = useTranslation()

  return (
    <Container>
      {stagesInfo.map((stage, index) => (
        <StageContainer key={index}>
          <StageBar>
            <StageFill fillPercentage={stage.percentage} isActive={stage.percentage > 0} />
          </StageBar>
          <StageLabel isActive={index === currentStage}>
            {index + 1 === 1
              ? t('tokenSaleInfo.1stStage')
              : index + 1 === 2
              ? t('tokenSaleInfo.2ndStage')
              : index + 1 === 3
              ? t('tokenSaleInfo.3rdStage')
              : null}
          </StageLabel>
        </StageContainer>
      ))}
    </Container>
  )
}

export default StageIndicator

export const Paths = {
  SWAP: '/swap',

  NOT_FOUND: '/not-found',

  DEFAULT: '/',

  POOLS: '/pools',
  POINTS: '/points',
  TOKEN_SALE: '/token-sale',
  DOCS: 'https://docs.xswap.ms/',
  DOCS_VI: 'https://docs.xswap.ms/v/xswap-vn',
}

import { useCallback, useEffect, useMemo, useState } from 'react'

import { useTronRefContract } from './tronweb'
import { useIntervalEffect } from './useIntervalEffect'
import { useLocalStorage } from './useLocalStorage'

const urlParams = new URLSearchParams(window.location.search)

export const useSavedRefCode = () => {
  const [refCode, setRefCode] = useLocalStorage<string>('refCode_v2', '')
  const [loading, setLoading] = useState(false)
  const [isCodeAvailable, setIsCodeAvailable] = useState(false)
  const [lastCheckedCode, setLastCheckedCode] = useState<string>()

  const refContractTrc = useTronRefContract()

  const checkCodeTRC = useCallback(
    async (refCodeText: string): Promise<boolean> => {
      if (!refCodeText || !refContractTrc) {
        return false
      }

      if (lastCheckedCode === refCodeText) {
        return isCodeAvailable
      }

      setLoading(true)
      try {
        const codeHash = await refContractTrc.getReferralCodeHash(refCodeText).call()

        const result = await refContractTrc.isReferralCodeExist(codeHash).call()

        setIsCodeAvailable(result)

        setLoading(false)

        setLastCheckedCode(refCodeText)

        return result
      } catch (e) {
        console.error('refCode Failed to check referral code', e)
        return false
      }
    },
    [refContractTrc, isCodeAvailable, lastCheckedCode]
  )

  const checkHandler = useCallback(
    async (c: string) => {
      const trcResult = await checkCodeTRC(c)

      return trcResult
    },
    [checkCodeTRC]
  )

  const checkCodeInternal = useCallback(async () => {
    await checkCodeTRC(refCode)
  }, [checkCodeTRC, refCode])

  useIntervalEffect(checkCodeInternal, 1000)

  const handleChangeRefCode = useCallback(
    (code: string) => {
      setRefCode(code)
    },
    [setRefCode]
  )

  console.log('isCodeAvailable', isCodeAvailable)

  return {
    refCode,
    isCodeAvailable,
    setRefCode: handleChangeRefCode,
    loading,
    refContract: refContractTrc,
    checkCode: checkHandler,
  }
}

export const useReferralCodeFromParams = () => {
  const { setRefCode } = useSavedRefCode()

  const code = useMemo(() => {
    return urlParams.get('refCode')
  }, [])

  useEffect(() => {
    if (code) {
      setRefCode(code)
    }
  }, [code, setRefCode])

  return code
}

import { Skeleton, SkeletonOwnProps } from '@mui/material'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import CountdownTimer from './CountDownTimer'
import PriceDisplay from './PriceDisplay'
import StageIndicator, { IStageWithPercentage } from './StageIndicator'
import { getStagePrice, IStage, ITokenSaleData, useSaleDatesTrc } from './TimeLeftChecker'

const Container = styled.div`
  background-color: transparent;
  z-index: 1;
  border-radius: 32px;
  margin-bottom: 24px;
  width: 100%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 32px;
`

const BgContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;
  border-radius: 32px;

  background-image: url('/images/tokensale-info-bg.png');
  background-color: #f6f9fc;
  background-repeat: no-repeat;
  background-blend-mode: soft-light;
  background-size: cover;
  background-position: 200px center;
  mix-blend-mode: overlay;

  width: 100%;
  height: 100%;
  padding: 48px 24px 40px 24px;

  ${({ theme }) => theme.mediaWidth.upToTablet`
    padding: 1rem;
  `}
`

const PriceContainer = styled.div`
  display: grid;
  gap: 16px;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: space-between;

  ${({ theme }) => theme.mediaWidth.upToTablet`
  grid-template-columns: 1fr;
    align-items: center;
    gap: 1.5rem;
  `}
`
const BaseSkeleton = ({ sx, variant = 'rounded', ...props }: SkeletonOwnProps & { style?: React.CSSProperties }) => {
  return (
    <Skeleton
      variant={variant}
      sx={{ fontSize: '1rem', bgcolor: 'rgb(220,230,240)', borderRadius: '1rem', ...sx }}
      {...props}
    />
  )
}

const TokenSaleInfo = ({ tokenSaleData, currentPrice }: { tokenSaleData: ITokenSaleData; currentPrice: number }) => {
  const { stagesInfo = [], currentStage = 0, isLoading } = tokenSaleData

  const nextPrice = useMemo(
    () => (stagesInfo[currentStage + 1] ? getStagePrice(stagesInfo[currentStage + 1]) : 0),
    [stagesInfo, currentStage]
  )

  const currentStageTimes = useMemo(() => {
    return {
      endTime: stagesInfo[currentStage]?.deadline * 1000,
    }
  }, [stagesInfo, currentStage])

  const { startTime } = useSaleDatesTrc()

  const stagesInfoWithPercentage = useMemo(() => {
    return stagesInfo.reduce((acc: IStageWithPercentage[], stage: IStage, index: number) => {
      const startTimeNumber = index === 0 ? startTime?.getTime() : acc[index - 1].deadline * 1000

      const percentage =
        startTimeNumber && startTimeNumber < Date.now()
          ? (100 * (Date.now() - startTimeNumber)) / (stage.deadline * 1000 - startTimeNumber)
          : 0
      return [...acc, { ...stage, percentage }]
    }, [])
  }, [stagesInfo, startTime])

  const { t } = useTranslation()

  return (
    <Container>
      <BgContainer>
        {isLoading ? (
          <BaseSkeleton variant="rectangular" width="100%" height="145px" animation="pulse" />
        ) : (
          <>
            <PriceContainer>
              <>
                <PriceDisplay label={t('tokenSaleInfo.currentPrice')} price={currentPrice} />
                <CountdownTimer endDate={currentStageTimes.endTime} />
                <PriceDisplay label={t('tokenSaleInfo.nextPrice')} price={nextPrice} />
              </>
            </PriceContainer>

            <StageIndicator stagesInfo={stagesInfoWithPercentage} currentStage={currentStage} />
          </>
        )}
      </BgContainer>
    </Container>
  )
}

export default TokenSaleInfo

const BA_LIST = 'https://raw.githubusercontent.com/The-Blockchain-Association/sec-notice-list/master/ba-sec-list.json'
// TODO(WEB-2282): Re-enable CMC list once we have a better solution for handling large lists.
const CMC_ALL_LIST = 'https://s3.coinmarketcap.com/generated/dex/tokens/eth-tokens-all.json'
const COINGECKO_LIST = 'https://tokens.coingecko.com/uniswap/all.json'
const UNSUPPORTED_LIST_URLS: string[] = [BA_LIST]

// default lists to be 'active' aka searched across
const DEFAULT_ACTIVE_LIST_URLS: string[] = []
const DEFAULT_INACTIVE_LIST_URLS: string[] = [CMC_ALL_LIST, COINGECKO_LIST, ...UNSUPPORTED_LIST_URLS]

export const DEFAULT_LIST_OF_LISTS: string[] = [...DEFAULT_ACTIVE_LIST_URLS, ...DEFAULT_INACTIVE_LIST_URLS]

import { JsonRpcProvider } from '@ethersproject/providers'
import { Stack } from '@mui/material'
import { useWallet } from '@tronweb3/tronwallet-adapter-react-hooks'
import Loading from 'components/Loading'
import {
  ARBITRUM_SALE_ADDRESS,
  BSC_SALE_ADDRESS,
  ETH_SALE_ADDRESS,
  POLYGON_SALE_ADDRESS,
  SALE_ABI,
  useTronSaleAddress,
} from 'constants/app-contracts'
import { SupportedChainId } from 'constants/chainsinfo'
import { RPC_URLS } from 'constants/networks'
import { BigNumber } from 'ethers'
import { useTronWebContract } from 'hooks/tronweb'
import useBlockNumber from 'hooks/useBlockNumber'
import { useIntervalEffect } from 'hooks/useIntervalEffect'
import { useIsMobileDevice } from 'hooks/useIsMobileDevice'
import { useActiveWeb3React } from 'hooks/web3'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { getContract } from 'utils'
import { ZERO } from 'utils/isZero'
import { formatDecimal } from 'utils/numberWithCommas'

import SWA from '../../assets/svg/swa-token.svg'

const Box = styled.div`
  display: flex;
  padding: 2.5rem 2rem;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 1.5rem;
  background: #fff;
  min-width: 20rem;

  ${({ theme }) => theme.mediaWidth.upToTablet`
    min-width: 0;
    padding: 1.5rem;
  `};
`

const Title = styled.h3`
  color: ${({ theme }) => theme.text50};
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 1.5rem */
  text-transform: uppercase;
  margin: 0;

  ${({ theme }) => theme.mediaWidth.upToTablet`
    font-size: 0.9375rem;
  `};
`

const TokenIcon = styled.img`
  width: 2rem;
  aspect-ratio: 1;

  ${({ theme }) => theme.mediaWidth.upToTablet`
    width: 1.5rem;
  `};
`

const Amount = styled.span`
  font-size: 1.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 2.25rem */
  letter-spacing: -0.01875rem;
  position: relative;
  top: 0.125rem;

  ${({ theme }) => theme.mediaWidth.upToTablet`
    font-size: 1.5rem;
  `};
`

const useGetUserPurchase = () => {
  const [balance, setBalance] = useState<BigNumber>(ZERO)
  const [loading, setLoading] = useState<boolean>(false)

  const { address: account } = useWallet()

  const saleAddress = useTronSaleAddress()
  const contract = useTronWebContract(saleAddress)

  const fetchBalance = useCallback(async () => {
    if (!saleAddress || !account || !contract) return

    setLoading(true)

    const data = await contract.userPurchases(account).call()

    setBalance(BigNumber.from(data.tokensPurchased || ZERO))

    setLoading(false)
  }, [saleAddress, account, contract])

  useIntervalEffect(fetchBalance, 3000)

  return { balance, loading }
}

const SALES_EVM = [
  {
    chainId: SupportedChainId.BNB, // BSC chain ID
    address: BSC_SALE_ADDRESS,
  },
  {
    chainId: SupportedChainId.ARBITRUM_ONE, // Arbitrum chain ID
    address: ARBITRUM_SALE_ADDRESS,
  },
  {
    chainId: SupportedChainId.POLYGON, // Polygon chain ID
    address: POLYGON_SALE_ADDRESS,
  },
  {
    chainId: SupportedChainId.MAINNET, // Ethereum chain ID
    address: ETH_SALE_ADDRESS,
  },
]

const useEvmPurchased = () => {
  const [value, setValue] = useState<BigNumber>(ZERO)
  const [loading, setLoading] = useState<boolean>(false)

  const [error, setError] = useState<string>('')

  const { account } = useActiveWeb3React()
  const blockNumber = useBlockNumber()

  useEffect(() => {
    const fetch = async () => {
      if (!account || !blockNumber) return

      setLoading(true)

      try {
        const promises = SALES_EVM.map((sale) => {
          const rpcProvider = new JsonRpcProvider(RPC_URLS[sale.chainId][0])
          const contract = getContract(sale.address, SALE_ABI, rpcProvider)
          return contract
            ?.userPurchases(account)
            .then((res: any) => {
              return BigNumber.from(res.tokensPurchased)
            })
            .catch((error: any) => {
              setError(error)
              return BigNumber.from(ZERO)
            })
        })

        const results = await Promise.all(promises)

        setValue(results.reduce((acc, curr) => (curr ? acc.add(curr) : acc), BigNumber.from(ZERO)))
      } catch (error) {
        console.log('fetching evm purchases error', error)
      } finally {
        setLoading(false)
      }
    }

    fetch()
  }, [account, blockNumber])

  return { value, loading, error, account }
}

export const Statistic = () => {
  const isMobile = useIsMobileDevice()

  const { balance: tronPurchase, loading: tronLoading } = useGetUserPurchase()
  const { value: evmPurchase, loading: evmLoading, error, account } = useEvmPurchased()

  const { t } = useTranslation()

  return (
    <Box>
      <Title>{t('TokenSale.PurchasedAmount')}</Title>
      <Stack direction="row" gap=".75rem" marginTop={isMobile ? '.37rem' : '.5rem'}>
        <TokenIcon src={SWA} />
        <Amount>
          <Loading loading={evmLoading || tronLoading}>{formatDecimal(tronPurchase.add(evmPurchase), 2)} SWA</Loading>
        </Amount>
      </Stack>
    </Box>
  )
}

import { WalletConnectAdapter } from '@tronweb3/tronwallet-adapter-walletconnect'
import { TRON_OWNER, useTronReferralsAddress } from 'constants/app-contracts'
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'
// @ts-ignore
import TronWeb from 'tronweb'

export const walletConnectTron = new WalletConnectAdapter({
  network: 'Mainnet',
  options: {
    relayUrl: 'wss://relay.walletconnect.com',
    projectId: process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID,
    metadata: {
      name: 'XSwap',
      description: 'XSwap WalletConnect',
      url: 'https://xswap.ms/',
      icons: ['https://xswap.ms/assets/svg/logo.svg'],
    },
  },
  web3ModalConfig: {
    themeMode: 'dark',
    themeVariables: {
      '--wcm-z-index': '1000',
    },
    /**
     * Recommended Wallets are fetched from WalletConnect explore api:
     * https://walletconnect.com/explorer?type=wallet&version=2.
     * You can copy these ids from the page.
     */
    explorerRecommendedWalletIds: [
      '4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0',
      '8a0ee50d1f22f6651afcae7eb4253e52a3310b90af5daef78a8c4929a9bb99d4',
      '15c8b91ade1a4e58f3ce4e7a0dd7f42b47db0c8df7e0d84f63eb39bcb96c4e0f',
    ],
  },
})

const tronWeb: TronWeb = new TronWeb({
  // fullHost: 'https://api.trongrid.io',
  // fullHost: 'https://rpc.ankr.com/http/tron',
  fullHost: 'https://yolo-greatest-river.tron-mainnet.quiknode.pro/450cae651f17b527dc38bacbc73184a40feb8ae2/',
})
tronWeb.setAddress(TRON_OWNER)
;(window as any).tronWeb1 = tronWeb

export const getTronProvider = (internal = false) => {
  const { tronWeb, tronWeb1 } = window as any

  if (internal) {
    return tronWeb
  }

  const target = tronWeb || tronWeb1

  return target
}

export const TRON_CONNECTIONS = {
  injected: {
    name: 'Injected',
    provider: getTronProvider(),
  },
}

const TronContractContext = createContext<{
  setContract: (address: string, contract: any) => void
  contracts: { [address: string]: any }
}>({} as any)

export const TronContractProvider = ({ children }: { children: any }) => {
  const [contracts, setContracts] = useState<{ [address: string]: any }>({})

  const setContract = useCallback((address: string, contract: any) => {
    setContracts((prev) => ({ ...prev, [address]: contract }))
  }, [])

  const data = useMemo(() => {
    return {
      contracts,
      setContract,
    }
  }, [contracts, setContract])

  return <TronContractContext.Provider value={data}>{children}</TronContractContext.Provider>
}

export const useTronWebContract = (address = '') => {
  const ctx = useContext(TronContractContext)

  const { setContract, contracts } = ctx
  const contract = useMemo(() => contracts[address], [address, contracts])

  useEffect(() => {
    if (contract) return

    const target = getTronProvider()

    const fetchContract = async () => {
      try {
        if (!address || contract) return

        const newContract = await target.contract().at(address)

        setContract(address, newContract)
      } catch (e) {
        console.error('useTronWebContract error', e)
      }
    }

    fetchContract()

    if (target) {
      const tId = setInterval(fetchContract, 1000)

      return () => clearInterval(tId)
    }

    return undefined
  }, [address, contract, setContract])

  return contract
}

export const useTronRefContract = () => {
  const refAddressTrc = useTronReferralsAddress()
  return useTronWebContract(refAddressTrc)
}
